import React from 'react';
import FormatPage from '../../atoms/FormatPage/FormatPage';
import FitOnePage from '../../atoms/FitOnePage/FitOnePage';
import TrimpParent from '../../atoms/TrimpParent/TrimpParent';
import './PageTab.scss';
import MakeAvailableForIpad from '../../atoms/MakeAvailableForIpad/MakeAvailableForIpad';

export default function PageTab({
  t,
  formatExport,
  setFormatExport,
  isFitOnePage,
  setIsFitOnePage,
  isTrimParent,
  setIsTrimParent,
  makeAvailableForIpad,
  setMakeAvailableForIpad,
  isSchedule
}) {
  return (
    <div className="tab--page">
      <div className="tab--page__title">
        <h5>{t('export_pdf_modal.tabs_page_subtitle')}</h5>
      </div>
      <div className="tab--page__format">
        <label>{t('export_pdf_modal.select_format')}</label>
        <div className="format__dropdown">
          <FormatPage
            t={t}
            formatExport={formatExport}
            setFormatExport={setFormatExport}
            setIsFitOnePage={setIsFitOnePage}
          />
        </div>
      </div>
      <div className="tab--page__scaling">
        <label>{t('export_pdf_modal.scaling_options')}</label>
        <FitOnePage
          t={t}
          formatExport={formatExport}
          isFitOnePage={isFitOnePage}
          setIsFitOnePage={setIsFitOnePage}
        />
      </div>
      <div className="tab--page__trim">
        <TrimpParent
          t={t}
          isTrimParent={isTrimParent}
          setIsTrimParent={setIsTrimParent}
        />
      </div>
      {isSchedule && (
        <div className="tab--page__trim">
          <MakeAvailableForIpad
            t={t}
            makeAvailableForIpad={makeAvailableForIpad}
            setMakeAvailableForIpad={setMakeAvailableForIpad}
          />
        </div>
      )}
    </div>
  );
}
