import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import cn from 'classnames';
import { Switch } from 'antd';
import { Colors } from '../../../constants/colors.constants';
import LabelSystem from '../LabelSystem';
import TooltipSystemV2 from '../TooltipSystemV2';
import { InformationCircleIcon } from '../../../icons';

const SwitchSystem = ({
  theme = 'light',
  id,
  label,
  checked,
  onChange,
  size = 'default',
  infoTooltip,
  infoTooltipPlacement = 'top',
  className,
  containerClassName,
  ...props
}) => {
  const inputId = useMemo(() => id || `switch-${nanoid()}`, [id]);

  return (
    <span
      className={cn(
        'switch-design-system',
        `theme--${theme}`,
        containerClassName
      )}>
      <Switch
        id={inputId}
        checked={checked}
        onChange={onChange}
        size={size}
        className={cn('switch-design-system__input', className)}
        {...props}
      />
      <LabelSystem theme={theme} htmlFor={inputId}>
        {label}
      </LabelSystem>
      {infoTooltip && (
        <TooltipSystemV2 title={infoTooltip} placement={infoTooltipPlacement}>
          <span className="switch-design-system__info-tooltip-icon">
            <InformationCircleIcon
              color={theme === 'light' ? Colors.DARK_GRAY : Colors.BED_ROCK}
            />
          </span>
        </TooltipSystemV2>
      )}
    </span>
  );
};

SwitchSystem.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  id: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['default', 'small']),
  infoTooltip: PropTypes.string,
  infoTooltipPlacement: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  props: PropTypes.object
};

export default SwitchSystem;
