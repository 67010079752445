import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TooltipSystemV2 from '../TooltipSystemV2';

const SplitTextSystem = ({ name, children, placement }) => {
  const divRef = useRef(null);

  const [isTextTruncation, setIsTextTruncation] = useState(false);

  useEffect(() => {
    const container = divRef.current;
    if (!container) {
      setIsTextTruncation(false);
      return;
    }
    const { scrollWidth, clientWidth } = container;
    const isTooltip = scrollWidth > clientWidth;
    setIsTextTruncation(isTooltip);
  }, [divRef]);

  return (
    <div className="splitText-design-system__container" ref={divRef}>
      {isTextTruncation
        ? TooltipSystemV2({
            title: name,
            placement,
            children
          })
        : children}
    </div>
  );
};
SplitTextSystem.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string.isRequired
};

export default SplitTextSystem;
