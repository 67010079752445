import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import SwitchSystem from '../SwitchSystem';
import TextareaSystem from '../TextareaSystem';

const FOCUS_DELAY_MS = 100;

const SwitchableTextareaSystem = ({
  theme = 'light',
  id,
  label,
  defaultValue,
  value,
  setValue,
  disabled = false,
  infoTooltip,
  ...props
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const textareaRef = useRef(null);
  const inputId = useMemo(() => id || `textarea-${nanoid()}`, [id]);

  useEffect(() => {
    if (value === undefined) {
      setIsEditable(false);
      setValue(defaultValue);
    }
  }, [value]);

  useEffect(() => {
    if (!isEditable) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setValue(isEditable ? '' : defaultValue);

    if (isEditable) {
      setTimeout(() => textareaRef.current?.focus(), FOCUS_DELAY_MS);
    }
  }, [isEditable]);

  return (
    <div className="switchable-textarea-system">
      <SwitchSystem
        id={`${inputId}-switch`}
        theme={theme}
        onChange={() => setIsEditable(!isEditable)}
        checked={isEditable}
        label={label}
        infoTooltip={infoTooltip}
      />

      <TextareaSystem
        id={inputId}
        ref={textareaRef}
        theme={theme}
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled || !isEditable}
        {...props}
      />
    </div>
  );
};

SwitchableTextareaSystem.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  infoTooltip: PropTypes.string,
  props: PropTypes.object
};

export default SwitchableTextareaSystem;
