import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { CloseNotificationIcon } from '../../icons/Icons';
import {
  closeDrawer,
  cancelCloseDrawer
} from '../../redux/slices/hoveringStackSlice';
import colors from '../../stylesheets/variables.scss';
import styles from './Drawer.module.scss';
import { TimerManagerSingleton } from '../../utils/timerManager';

export const TestIds = {
  DRAWER: 'drawer_root',
  ICON: 'drawer_icon',
  TITLE: 'drawer_title',
  CLOSE_BUTTON: 'drawer_close_button'
};

export const Drawer = ({
  config,
  children,
  canClose,
  onClose,
  title,
  icon: IconComponent,
  className,
  contentClassName,
  containerRef = null,
  theme = 'default',
  ...actions
}) => {
  const { status, origin, loading } = config;
  const [isOpen, setIsOpen] = useState(false);
  const timerManager = TimerManagerSingleton.getInstance();

  const handleClose = async () => {
    if (!canClose || (await canClose())) {
      setIsOpen(false);
      const drawerCloseCallback = () => {
        actions.closeDrawer();
        onClose && onClose();
      };
      timerManager.registerAutoTimeout(
        drawerCloseCallback,
        150,
        'drawerCloseTimer'
      );
    } else {
      actions.cancelCloseDrawer();
    }
  };

  useEffect(() => {
    const drawerOpenCallback = () => setIsOpen(true);
    timerManager.registerAutoTimeout(drawerOpenCallback, 50, 'drawerOpenTimer');

    return () => {};
  }, []);

  useEffect(() => {
    if (status !== 'REQUESTED_CLOSING') return;

    const activityId = window.to_use_react_gantt?.activityCard;
    if (activityId) return;

    handleClose();
  }, [status]);

  return (
    <div
      className={cn(
        className,
        styles.drawer,
        styles[`drawer--${theme}`],
        styles[`drawer--${origin}`],
        {
          [styles['drawer--open']]: isOpen,
          [styles['drawer--loading']]: loading
        }
      )}
      data-testid={TestIds.DRAWER}
      ref={containerRef}
      tabIndex="-1">
      <div className={styles['drawer__loading-bar']} />
      <section className={styles.drawer__wrapper}>
        <div className={styles.drawer__header}>
          {IconComponent && (
            <IconComponent
              color={colors.brandOrange40}
              className={styles.drawer__icon}
              data-testid={TestIds.ICON}
            />
          )}
          {title && (
            <span className={styles.drawer__title} data-testid={TestIds.TITLE}>
              {title}
            </span>
          )}
          <button
            className={styles['drawer__close-button']}
            onClick={handleClose}
            data-testid={TestIds.CLOSE_BUTTON}>
            <CloseNotificationIcon color={colors.white} />
          </button>
        </div>
        <div className={cn(contentClassName, styles.drawer__content)}>
          {children}
        </div>
      </section>
    </div>
  );
};

const actionCreators = {
  closeDrawer,
  cancelCloseDrawer
};

export default connect(null, actionCreators)(Drawer);
