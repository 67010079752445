import React from 'react';
import DropdownSystem from '../../../../DesignSystem/DropdownSystem';
import TooltipSystemV2 from '../../../../DesignSystem/TooltipSystemV2';
import { PAGE_ORIENTATION_OPTIONS } from '../../../constants';
import { Colors } from '../../../../../constants/colors.constants';
import { ALL_CONTENT } from '../../../../../assets/gantt/ext/Export/ExportPDFGantt/constants';
import './PageOrientation.scss';

export default function PageOrientation({
  t,
  formatExport,
  pageOrientation,
  setPageOrientation
}) {
  const { label, Icon } = PAGE_ORIENTATION_OPTIONS[pageOrientation];
  const disabled = formatExport === ALL_CONTENT;

  const handleOrientationChange = (key) => {
    if (key) {
      setPageOrientation(key);
    }
  };

  return (
    <TooltipSystemV2
      title={
        disabled
          ? t('export_pdf_modal.options_disabled_for_one_page_tooltip')
          : null
      }
      placement="right">
      <div className={disabled ? 'page-orientation-select--disabled' : ''}>
        {DropdownSystem({
          theme: 'dark',
          version: 'v2',
          trigger: ['click'],
          height: 32,
          label: t(label),
          labelIcon: <Icon color={Colors.PRIMARY} />,
          items: Object.entries(PAGE_ORIENTATION_OPTIONS).map(
            ([key, { label, Icon }]) => ({
              icon: (
                <Icon
                  color={
                    key === pageOrientation ? Colors.BLACK : Colors.PRIMARY
                  }
                />
              ),
              key: key,
              label: t(label),
              onClick: () => handleOrientationChange(key),
              disabled: false,
              isDivider: false,
              active: key === pageOrientation,
              subitems: []
            })
          ),
          disabled
        })}
      </div>
    </TooltipSystemV2>
  );
}
