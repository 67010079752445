import React, { useEffect, useState, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Input } from 'antd';
import LabelSystem from '../LabelSystem';

const TextareaSystem = forwardRef(
  (
    {
      theme = 'light',
      id,
      label,
      value,
      onChange,
      rows,
      maxLength,
      allowExceedLength = true,
      isError,
      disabled,
      containerClassName,
      ...props
    },
    ref
  ) => {
    const [length, setLength] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
      setLength(value.length);
    }, [value]);

    const isExceeded = maxLength && length > maxLength;
    const isAtLimit = maxLength && length === maxLength;
    const inputId = useMemo(() => id || `textarea-${nanoid()}`, [id]);

    return (
      <div
        className={cn(
          `textarea-design-system theme--${theme}`,
          containerClassName,
          {
            error: isError || isExceeded
          }
        )}>
        <LabelSystem
          theme={theme}
          htmlFor={inputId}
          className="textarea-design-system__label"
          isError={isError || isExceeded}>
          {label}
        </LabelSystem>

        <Input.TextArea
          id={inputId}
          ref={ref}
          value={value}
          onChange={onChange}
          rows={rows}
          disabled={disabled}
          maxLength={allowExceedLength ? null : maxLength}
          {...props}
        />

        {maxLength && (
          <div
            className={cn('textarea-design-system__counter', {
              exceeded: isExceeded,
              'at-limit': isAtLimit,
              disabled
            })}>
            <div>
              {isExceeded && <span>{t('textareaSystem.exceeded')}</span>}
            </div>
            <div>{`${length}/${maxLength}`}</div>
          </div>
        )}
      </div>
    );
  }
);

TextareaSystem.displayName = 'TextareaSystem';

TextareaSystem.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  allowExceedLength: PropTypes.bool,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string
};

export default TextareaSystem;
