import React from 'react';
import PrintDatePicker from '../../atoms/PrintDatePicker';
import AlignLineCheck from '../../atoms/AlignLineCheck/AlignLineCheck';
import CheckSystem from '../../../../DesignSystem/CheckSystem';

export default function Personalization({
  t,
  isSchedule,
  dateSelected,
  setDateSelected,
  isAlignLine,
  setIsAlignLine,
  showOwnerLogo,
  setShowOwnerLogo,
  showOrganizationLogo,
  setShowOrganizationLogo,
  showFooterLegend,
  setShowFooterLegend
}) {
  return (
    <div className="export-pdf-personalization-tab">
      <section>
        <h5>{t('export_pdf_modal.print_date')}</h5>
        <PrintDatePicker
          t={t}
          isSchedule={isSchedule}
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
        />
        <AlignLineCheck
          t={t}
          isSchedule={isSchedule}
          dateSelected={dateSelected}
          isAlignLine={isAlignLine}
          setIsAlignLine={setIsAlignLine}
        />
      </section>

      <section>
        <h5>{t('export_pdf_modal.visualization_options')}</h5>
        <CheckSystem
          checked={showOwnerLogo}
          onClickFunction={setShowOwnerLogo}
          withLabel
          label={t('export_pdf_modal.show_owner_logo')}
        />
        <CheckSystem
          checked={showOrganizationLogo}
          onClickFunction={setShowOrganizationLogo}
          withLabel
          label={t('export_pdf_modal.show_organization_logo')}
        />
        <CheckSystem
          checked={showFooterLegend}
          onClickFunction={setShowFooterLegend}
          withLabel
          label={t('export_pdf_modal.show_footer_legend')}
        />
      </section>
    </div>
  );
}
