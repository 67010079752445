import React from 'react';
import moment from 'moment';
import DatePickerSystem from '../../../../DesignSystem/DatePickerSystem';
import LabelSystem from '../../../../DesignSystem/LabelSystem';
import { FORMAT_DEFAULT } from '../../../constants/index';
import { getGanttToExport } from '../../../../../assets/gantt/ext/Export/ExportPDFGantt/utils/gantt.utils';

export default function PrintDatePicker({
  t,
  isSchedule,
  dateSelected,
  setDateSelected
}) {
  const now = new Date();
  const gantt = getGanttToExport(isSchedule);
  const dateFormat = gantt?.currentDateFormat || FORMAT_DEFAULT;
  const defaultValue = moment(dateSelected || now, dateFormat);

  return (
    <div className="export-pdf-print-date-picker">
      <LabelSystem theme="dark">
        {t('export_pdf_modal.select_date')}
      </LabelSystem>

      <div className="export-pdf-print-date-picker__date-picker">
        {DatePickerSystem({
          theme: 'dark',
          format: dateFormat,
          defaultValue: defaultValue,
          defaultPickerValue: defaultValue,
          allowClear: false,
          onChange: (date) => setDateSelected(date?.toDate())
        })}
      </div>
    </div>
  );
}
