import React from 'react';
import DropdownSystem from '../../../../DesignSystem/DropdownSystem';
import { getLabelPageFormat } from '../../../helpers';
import {
  ALL_CONTENT,
  LANDSCAPE
} from '../../../../../assets/gantt/ext/Export/ExportPDFGantt/constants';
import { PAPER_FORMAT } from '../../../constants';

export default function FormatPage({
  t,
  formatExport,
  setFormatExport,
  setIsFitOnePage,
  setPageOrientation
}) {
  const handleFormatChange = (key) => {
    if (!key) return;

    setFormatExport(key);
    if (key === ALL_CONTENT) {
      setIsFitOnePage(false);
      setPageOrientation(LANDSCAPE);
    }
  };

  return (
    <>
      {DropdownSystem({
        theme: 'dark',
        version: 'v2',
        trigger: ['click'],
        height: 32,
        label: getLabelPageFormat({ t, pageFormat: formatExport }),
        withRadioButtons: true,
        items: PAPER_FORMAT.map(([key, value]) => ({
          key: key,
          label: t(value),
          onClick: () => handleFormatChange(key),
          disabled: false,
          isDivider: false,
          active: key === formatExport,
          subitems: []
        }))
      })}
    </>
  );
}
