import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import FormatPage from '../../atoms/FormatPage/FormatPage';
import FitOnePage from '../../atoms/FitOnePage/FitOnePage';
import PageOrientation from '../../atoms/PageOrientation/PageOrientation';
import TrimParent from '../../atoms/TrimParent/TrimParent';
import MakeAvailableForIpad from '../../atoms/MakeAvailableForIpad/MakeAvailableForIpad';
import SwitchableTextareaSystem from '../../../../DesignSystem/SwitchableTextareaSystem';
import {
  getCurrentProjectState,
  getCurrentSectorState
} from '../../../../../utils/userUtils';
import LabelSystem from '../../../../DesignSystem/LabelSystem';
export default function GeneralTab({
  t,
  title,
  setTitle,
  subtitle,
  setSubtitle,
  formatExport,
  setFormatExport,
  isFitOnePage,
  setIsFitOnePage,
  pageOrientation,
  setPageOrientation,
  isTrimParent,
  setIsTrimParent,
  makeAvailableForIpad,
  setMakeAvailableForIpad,
  isSchedule
}) {
  const projectState = useSelector((state) => state.projectState);
  const projectName = useMemo(
    () => getCurrentProjectState(projectState).name,
    [projectState]
  );
  const sectorName = useMemo(
    () => getCurrentSectorState(projectState).name,
    [projectState]
  );

  return (
    <div className="export-pdf-general-tab">
      <section>
        <SwitchableTextareaSystem
          theme="dark"
          label={t('export_pdf_modal.edit_title')}
          defaultValue={projectName}
          value={title}
          setValue={setTitle}
          rows={2}
          maxLength={90}
          placeholder={t('export_pdf_modal.edit_title_placeholder')}
          infoTooltip={t('export_pdf_modal.edit_title_info_tooltip')}
        />

        <SwitchableTextareaSystem
          theme="dark"
          label={t('export_pdf_modal.edit_subtitle')}
          defaultValue={sectorName}
          value={subtitle}
          setValue={setSubtitle}
          rows={2}
          maxLength={90}
          placeholder={t('export_pdf_modal.edit_subtitle_placeholder')}
          infoTooltip={t('export_pdf_modal.edit_subtitle_info_tooltip')}
        />
      </section>

      <section>
        <h5>{t('export_pdf_modal.page_options')}</h5>
        <div className="export-pdf-general-tab__page-options">
          <div className="export-pdf-general-tab__dropdown">
            <LabelSystem theme="dark">
              {t('export_pdf_modal.select_format')}
            </LabelSystem>
            <FormatPage
              t={t}
              formatExport={formatExport}
              setFormatExport={setFormatExport}
              setIsFitOnePage={setIsFitOnePage}
              setPageOrientation={setPageOrientation}
            />
          </div>

          <div className="export-pdf-general-tab__dropdown">
            <LabelSystem theme="dark">
              {t('export_pdf_modal.orientation')}
            </LabelSystem>
            <PageOrientation
              t={t}
              formatExport={formatExport}
              pageOrientation={pageOrientation}
              setPageOrientation={setPageOrientation}
            />
          </div>
        </div>

        <FitOnePage
          t={t}
          formatExport={formatExport}
          isFitOnePage={isFitOnePage}
          setIsFitOnePage={setIsFitOnePage}
        />
      </section>

      <section>
        <h5>{t('export_pdf_modal.other_options')}</h5>
        <TrimParent
          t={t}
          isTrimParent={isTrimParent}
          setIsTrimParent={setIsTrimParent}
        />

        {isSchedule && (
          <MakeAvailableForIpad
            t={t}
            makeAvailableForIpad={makeAvailableForIpad}
            setMakeAvailableForIpad={setMakeAvailableForIpad}
          />
        )}
      </section>
    </div>
  );
}
