import { LandscapeIcon, PortraitIcon } from '../../../icons';

export const FORMAT_DEFAULT = 'MM/DD/YY';
export const TABS = { GENERAL: 1, PERSONALIZATION: 2 };
export const PAPER_FORMAT = [
  ['ALL_CONTENT', 'export_pdf_modal.format_option.one_page'],
  ['Tabloid', 'Tabloid - 11 x 17 in'],
  ['A3', 'A3 - 11.7 x 16.5 in'],
  ['Arch_C', 'Arch C - 18 x 24 in'],
  ['Arch_D', 'Arch D - 24 x 36 in']
];

export const PAGE_ORIENTATION_OPTIONS = {
  landscape: {
    label: 'export_pdf_modal.orientation_options.landscape',
    Icon: LandscapeIcon
  },
  portrait: {
    label: 'export_pdf_modal.orientation_options.portrait',
    Icon: PortraitIcon
  }
};
