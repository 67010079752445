import React from 'react';
import CheckSystem from '../../../../DesignSystem/CheckSystem';
import { InformationCircleIcon } from '../../../../../icons';
import './MakeAvailableForIpad.scss';
import TooltipSystemV2 from '../../../../DesignSystem/TooltipSystemV2';

export default function MakeAvailableForIpad({
  t,
  makeAvailableForIpad,
  setMakeAvailableForIpad
}) {
  return (
    <div className="make-available-for-ipad-container">
      <CheckSystem
        checked={makeAvailableForIpad}
        onClickFunction={setMakeAvailableForIpad}
        withLabel={true}
        label={t('export_pdf_modal.make_available_for_ipad')}
      />
      <TooltipSystemV2
        title={t('export_pdf_modal.make_available_for_ipad_tooltip')}
        placement="right">
        <span className="make-available-for-ipad-info-icon">
          <InformationCircleIcon color="#7D8671" />
        </span>
      </TooltipSystemV2>
    </div>
  );
}
