import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import * as Sentry from '@sentry/react';
import ModalSystem from '../../components/DesignSystem/ModalSystem';
import ButtonSystem from '../../components/DesignSystem/ButtonSystem';
import { PdfIcon } from '../../icons';
import { checkBackendAccess } from '../../utils/checkBackendAccess';
import { exportPDFGantt } from '../../assets/gantt/ext/Export/ExportPDFGantt';
import {
  ALL_CONTENT,
  LANDSCAPE
} from '../../assets/gantt/ext/Export/ExportPDFGantt/constants';
import { Colors } from '../../constants/colors.constants';
import { hideOpenDropdown } from './helpers';
import GeneralTab from './components/molecules/GeneralTab';
import { TABS } from './constants/index';
import PersonalizationTab from './components/molecules/PersonalizationTab/PersonalizationTab';
import './index.scss';
import { activityPdfService } from '../../services';
import { showNetworkBlockAlert } from '../../assets/gantt/ext/Export/ExportPDFGantt/helpers/notifyUser';
import { getGanttToExport } from '../../assets/gantt/ext/Export/ExportPDFGantt/utils/gantt.utils';

const MAX_LENGTH_TITLE = 90;
const MAX_LENGTH_SUBTITLE = 90;

const ExportPdfModal = ({
  t,
  isSchedule = true,
  isVisible,
  setIsVisible,
  handleClose,
  openFitToOnePageModal = () => {}
}) => {
  const [currentTab, setCurrentTab] = useState(TABS.GENERAL);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [title, setTitle] = useState(undefined);
  const [subtitle, setSubtitle] = useState(undefined);
  const [dateSelected, setDateSelected] = useState(null);
  const [isFitOnePage, setIsFitOnePage] = useState(false);
  const [isAlignLine, setIsAlignLine] = useState(false);
  const [formatExport, setFormatExport] = useState(ALL_CONTENT);
  const [pageOrientation, setPageOrientation] = useState(LANDSCAPE);
  const [isTrimParent, setIsTrimParent] = useState(false);
  const [makeAvailableForIpad, setMakeAvailableForIpad] = useState(false);
  const [showOwnerLogo, setShowOwnerLogo] = useState(true);
  const [showOrganizationLogo, setShowOrganizationLogo] = useState(true);
  const [showFooterLegend, setShowFooterLegend] = useState(true);
  const projectState = useSelector((state) => state.projectState);

  const isFormValid = useMemo(() => {
    const isTitleValid = title?.length <= MAX_LENGTH_TITLE;
    const isSubtitleValid = subtitle?.length <= MAX_LENGTH_SUBTITLE;
    return isTitleValid && isSubtitleValid;
  }, [title, subtitle]);

  useEffect(() => {
    if (isVisible) {
      hideOpenDropdown();
    }
  }, [isVisible]);

  const getCurrentTab = (tab = TABS.GENERAL) => {
    const tabs = {
      [TABS.GENERAL]: (
        <GeneralTab
          t={t}
          title={title}
          setTitle={setTitle}
          subtitle={subtitle}
          setSubtitle={setSubtitle}
          formatExport={formatExport}
          setFormatExport={setFormatExport}
          pageOrientation={pageOrientation}
          setPageOrientation={setPageOrientation}
          isFitOnePage={isFitOnePage}
          setIsFitOnePage={setIsFitOnePage}
          isTrimParent={isTrimParent}
          setIsTrimParent={setIsTrimParent}
          makeAvailableForIpad={makeAvailableForIpad}
          setMakeAvailableForIpad={setMakeAvailableForIpad}
          isSchedule={isSchedule}
        />
      ),
      [TABS.PERSONALIZATION]: (
        <PersonalizationTab
          t={t}
          isSchedule={isSchedule}
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
          isAlignLine={isAlignLine}
          setIsAlignLine={setIsAlignLine}
          showOwnerLogo={showOwnerLogo}
          setShowOwnerLogo={setShowOwnerLogo}
          showOrganizationLogo={showOrganizationLogo}
          setShowOrganizationLogo={setShowOrganizationLogo}
          showFooterLegend={showFooterLegend}
          setShowFooterLegend={setShowFooterLegend}
        />
      )
    };

    return tabs[tab];
  };

  const handleCloseModal = () => {
    setTitle(undefined);
    setSubtitle(undefined);
    setIsVisible(false);
    setCurrentTab(TABS.GENERAL);
    setFormatExport(ALL_CONTENT);
    setPageOrientation(LANDSCAPE);
    setIsFitOnePage(false);
    setIsTrimParent(false);
    setMakeAvailableForIpad(false);
    setDateSelected(null);
    setIsAlignLine(false);
    setShowOwnerLogo(true);
    setShowOrganizationLogo(true);
    setShowFooterLegend(true);
    handleClose();
  };

  const createPDFGanttExport = async () => {
    const gantt = getGanttToExport(isSchedule);
    if (!gantt) return;

    const now = new Date();
    const isShowTodayLinePDF = gantt.config.show_todayline;
    const printedOn = dateSelected || now;
    const alignLineDate = isAlignLine ? dateSelected : now;
    await exportPDFGantt({
      isSchedule,
      t,
      openFitToOnePageModal,
      projectState,
      config: {
        title,
        subtitle,
        makeAvailableForIpad,
        isTrimParent,
        isFitOnePage,
        formatToExport: formatExport,
        pageOrientation,
        isShowTodayLinePDF,
        printedOn,
        alignLineDate,
        showOwnerLogo,
        showOrganizationLogo,
        showFooterLegend
      },
      handleCloseModal
    });
  };

  const handleExport = async () => {
    setLoadingPdf(true);
    try {
      const statusServer = await checkBackendAccess(activityPdfService);
      if (!statusServer) {
        showNetworkBlockAlert({ t, isSchedule });
        return;
      }

      await createPDFGanttExport();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingPdf(false);
      handleCloseModal();
    }
  };

  const contentModal = () => (
    <div className="export-pdf-modal">
      <div className="export-pdf-modal__tabs">
        <div
          className={cn('export-pdf-modal__tabs-tab', {
            'export-pdf-modal__tabs-tab--active': currentTab === TABS.GENERAL
          })}
          onClick={() => setCurrentTab(TABS.GENERAL)}>
          {t('export_pdf_modal.tabs_general_title')}
        </div>
        <div
          className={cn('export-pdf-modal__tabs-tab', {
            'export-pdf-modal__tabs-tab--active':
              currentTab === TABS.PERSONALIZATION
          })}
          onClick={() => setCurrentTab(TABS.PERSONALIZATION)}>
          {t('export_pdf_modal.tabs_personalization_title')}
        </div>
      </div>

      <div className="export-pdf-modal__content">
        {getCurrentTab(currentTab)}
      </div>

      <div className="export-pdf-modal__footer">
        <ButtonSystem type="secondary" onClick={() => handleCloseModal(false)}>
          {t('cancel')}
        </ButtonSystem>

        <ButtonSystem
          type="primary"
          onClick={() => handleExport()}
          disabled={!isFormValid || loadingPdf}
          loading={loadingPdf}>
          {t('export_pdf_modal.download')}
        </ButtonSystem>
      </div>
    </div>
  );

  return ModalSystem({
    theme: 'dark',
    width: 600,
    title: t('export_pdf_modal.title'),
    showIconTitle: true,
    iconTitle: <PdfIcon color={Colors.PRIMARY} />,
    visible: isVisible,
    setVisible: setIsVisible,
    centered: true,
    children: contentModal(),
    onCancelFunction: () => handleCloseModal()
  });
};

export default withTranslation()(ExportPdfModal);
