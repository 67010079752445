import React from 'react';
import PrintDatePicker from '../../atoms/PrintDatePicker/PrintDatePicker';
import AlignLineCheck from '../../atoms/AlignLineCheck/AlignLineCheck';
import CheckSystem from '../../../../DesignSystem/CheckSystem';
import './PersonalizationTab.scss';

export default function Personalization({
  t,
  isSchedule,
  dateSelected,
  setDateSelected,
  isAlignLine,
  setIsAlignLine,
  showOwnerLogo,
  setShowOwnerLogo,
  showOrganizationLogo,
  setShowOrganizationLogo,
  showFooterLegend,
  setShowFooterLegend
}) {
  return (
    <div className="tab--personalization">
      <div className="tab--personalization__title">
        <h5>{t('export_pdf_modal.print_date')}</h5>
      </div>
      <div className="tab--personalization__select">
        <label>
          {t('export_pdf_modal.select_date')}
          <div className="select__picker">
            <PrintDatePicker
              isSchedule={isSchedule}
              dateSelected={dateSelected}
              setDateSelected={setDateSelected}
            />
          </div>
        </label>
      </div>
      <div className="tab--personalization__todayline">
        <AlignLineCheck
          t={t}
          isSchedule={isSchedule}
          dateSelected={dateSelected}
          isAlignLine={isAlignLine}
          setIsAlignLine={setIsAlignLine}
        />
      </div>
      
      <div className="tab--personalization__title">
        <h5>{t('export_pdf_modal.visualization_options')}</h5>
      </div>
      <div className="tab--personalization__visualization">
        <CheckSystem
          checked={showOwnerLogo}
          onClickFunction={setShowOwnerLogo}
          withLabel
          label={t('export_pdf_modal.show_owner_logo')}
        />
        <CheckSystem
          checked={showOrganizationLogo}
          onClickFunction={setShowOrganizationLogo}
          withLabel
          label={t('export_pdf_modal.show_organization_logo')}
        />
        <CheckSystem
          checked={showFooterLegend}
          onClickFunction={setShowFooterLegend}
          withLabel
          label={t('export_pdf_modal.show_footer_legend')}
        />
      </div>
    </div>
  );
}
