import React from 'react';
import moment from 'moment';
import DatePickerSystem from '../../../../DesignSystem/DatePickerSystem';
import { FORMAT_DEFAULT } from '../../../constants/index';
import { getGanttToExport } from '../../../../../assets/gantt/ext/Export/ExportPDFGantt/utils/gantt.utils';

export default function PrintDatePicker({
  isSchedule,
  dateSelected,
  setDateSelected
}) {
  const now = new Date();
  const gantt = getGanttToExport(isSchedule);
  const dateFormat = gantt?.currentDateFormat || FORMAT_DEFAULT;
  const defaultValue = moment(dateSelected || now, dateFormat);

  return (
    <>
      {DatePickerSystem({
        theme: 'dark',
        format: dateFormat,
        defaultValue: defaultValue,
        defaultPickerValue: defaultValue,
        allowClear: false,
        onChange: (date) => setDateSelected(date?.toDate())
      })}
    </>
  );
}
